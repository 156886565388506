import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { MEDIA } from './utils/styles'
import { GatsbyImage } from 'gatsby-plugin-image'
import useNavigationData from '../hooks/navigation'

const ScWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 3;
`

const ScBackground = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: absolute;
  z-index: -1;
`

const ScNavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  max-width: var(--size-max-width);
  align-items: center;
  margin: 0 auto;
  padding: var(--space-2xl) var(--size-gutter);
`

const ScNavigation = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  gap: var(--space-xl);
`

const ScNavigationItem = styled.li`
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  user-select: none;
  opacity: 1;
`

const ScLogoLink = styled(Link)`
  display: flex;
  font-weight: var(--medium);
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  gap: var(--space-md);
`

const ScLogo = styled(GatsbyImage)`
  height: var(--size-logo);
  width: var(--size-logo);
  display: block;
`

const ScBrand = styled.span`
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  user-select: none;
  opacity: 1;
  ${MEDIA.MOBILE`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  `}
`

const Navigation = () => {
  const { logo } = useNavigationData()
  return (
    <ScWrapper>
      <ScBackground />
      <ScNavBar role="navigation" aria-label="Main">
        <ScLogoLink to="/">
          <ScLogo image={logo.gatsbyImageData} alt={logo.title} />
          <ScBrand>THE FAT COW</ScBrand>
        </ScLogoLink>
        <ScNavigation>
          <ScNavigationItem>
            <Link to="/" activeClassName="active">
              Home
            </Link>
          </ScNavigationItem>
          <ScNavigationItem>
            <Link to="/menu/" activeClassName="active">
              Menu
            </Link>
          </ScNavigationItem>
        </ScNavigation>
      </ScNavBar>
    </ScWrapper>
  )
}

export default Navigation
