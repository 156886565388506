import { useStaticQuery, graphql } from 'gatsby'

const useLayoutData = () => {
  const { contentfulHome: data } = useStaticQuery(
    graphql`
      query LayoutQuery {
        contentfulHome(contentful_id: { eq: "6uAUZR10UxlXBcl5oTkzs" }) {
          faviconImage {
            title
            fluid(maxWidth: 32, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `
  )

  return {
    favicon: data.faviconImage,
  }
}

export default useLayoutData
