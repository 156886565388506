import React from 'react'
import { Helmet } from 'react-helmet'
import { MatchMediaProvider } from 'react-match-media-context'
import './variables.css'
import './global.css'
import Seo from './seo'
import Navigation from './navigation'
import { DEVICE_MINWIDTH } from './utils/styles'
import useLayoutData from '../hooks/layout'

const media = {
  isSmallest: {
    maxWidth: `414px`,
  },
  isMobile: {
    maxWidth: `${DEVICE_MINWIDTH.TABLET - 0.1}px`,
  },
  isTablet: {
    maxWidth: `${DEVICE_MINWIDTH.DESKTOP - 0.1}px`,
    minWidth: `${DEVICE_MINWIDTH.TABLET}px`,
  },
  isDesktop: {
    minWidth: `${DEVICE_MINWIDTH.DESKTOP}px`,
  },
}

const Layout = ({ title, children }) => {
  const { favicon } = useLayoutData()

  return (
    <MatchMediaProvider media={media}>
      <Helmet>
        <html lang="en" />
        {favicon && (
          <link rel="icon" type="image/png" href={favicon.fluid.src} />
        )}
      </Helmet>
      <Seo title={title} />
      <Navigation />
      <main>{children}</main>
    </MatchMediaProvider>
  )
}

export default Layout
