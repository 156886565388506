import { useStaticQuery, graphql } from 'gatsby'

const useNavigationData = () => {
  const { contentfulHome: data } = useStaticQuery(
    graphql`
      query NavigationQuery {
        contentfulHome(contentful_id: { eq: "6uAUZR10UxlXBcl5oTkzs" }) {
          logo {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              height: 24
              width: 24
            )
          }
        }
      }
    `
  )

  return {
    logo: data.logo,
  }
}

export default useNavigationData
